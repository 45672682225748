// function to detect if an element is scrolled into view
jQuery(window).on("load", function (e) {
  jQuery.fn.visible = function(partial) {
    var $t            = jQuery(this),
        $w            = jQuery(window),
        viewTop       = $w.scrollTop(),
        viewBottom    = viewTop + $w.height(),
        _top          = $t.offset().top,
        _bottom       = _top + $t.height(),
        compareTop    = partial === true ? _bottom : _top,
        compareBottom = partial === true ? _top : _bottom;
    return ((compareBottom <= viewBottom) && (compareTop >= viewTop));
  };

  setTimeout(function(){
    var win = jQuery(window);
    var allMods = jQuery("[data-inviewport]");

    // Already visible modules
    allMods.each(function(i, el) {
      var el = jQuery(el);
      if (el.visible(true)) {
        el.addClass(el.attr("data-animations"));
      }
    });

    win.scroll(function(event) {
      allMods.each(function(i, el) {
        var el = jQuery(el);
        if (el.visible(true)) {
          el.addClass(el.attr("data-animations"));
        }
      });
    });
  },100);

});

var widthWithScrollBars = '';

$(document).ready(function() {
  var widthWithScrollBars = $(window).width();
  var heightWindow = $(window).height();
  var scroll = $(window).scrollTop();
  var headerOffset = 20;

  // Header scroll func
  function scrollFunction(scroll){
    if (scroll >= headerOffset && ( ! $( 'body' ).hasClass( 'menu-opened' ) || ! $( 'body' ).hasClass( 'search-opened' ) ) ) {
      $('body:not(.search-opened), body:not(.menu-opened)').addClass('header-scroll');
    } else if (scroll < headerOffset && ( $( 'body' ).hasClass( 'menu-opened' ) || $( 'body' ).hasClass( 'search-opened' ) ) ) {
      $('body').addClass('header-scroll');
    }
    else{
      $('body').removeClass('header-scroll');
    }
  }
  // Header scroll page load
  scrollFunction(scroll);

  // Header scroll on page scroll
  $(window).scroll(function() {
    scroll = $(window).scrollTop();
    scrollFunction(scroll);
  });

  // Mobile menu
  $(document).on('click', '.menu-btn', function() {
    $('body').removeClass( 'search-opened' );
    $('body').toggleClass( 'menu-opened' );
    return false;
  });

  // Search menu
  $(document).on('click', '.search-btn', function() {
    $('body').removeClass( 'menu-opened' );
    $('body').toggleClass( 'search-opened' );
    return false;
  });

  $(document).on('click', '.search-btn, .menu-btn', function(){
    if ( ! $( 'body' ).hasClass( 'header-scroll' )) {
      $( 'body' ).addClass( 'header-scroll' );
    }

    if ( $( 'body' ).hasClass( 'header-scroll' ) && ( $( 'body' ).hasClass( 'menu-opened' ) || $( 'body' ).hasClass( 'search-opened' ) ) ){}
    else if ( scroll < headerOffset && ( ! $( 'body' ).hasClass( 'menu-opened' ) || ! $( 'body' ).hasClass( 'search-opened' ) ) ){
      $( 'body' ).removeClass( 'header-scroll' );
    }
    else if ( $( 'body' ).hasClass( 'menu-opened' ) || $( 'body' ).hasClass( 'search-opened' ) ){
      $( 'body' ).addClass( 'header-scroll' );
    }
  });


  // Main background video
  var activeVideo = '';

  // Play video function
  function playVideo(activeVideo) {
      var myVideo = document.getElementById(activeVideo);
      myVideo.play();
  }

  // Pause video function
  function pauseVideo(activeVideo) {
      var myVideo = document.getElementById(activeVideo);
      myVideo.pause();
  }

  // Unmute video function
  function unmuteVideo(activeVideo) {
      var myVideo = document.getElementById(activeVideo);
      myVideo.muted = false;
  }

  // Mute video function
  function muteVideo(activeVideo) {
      var myVideo = document.getElementById(activeVideo);
      myVideo.muted = true;
  }


  $(document).on( "click", ".hero-controls a", function() {

    activeVideo = $(this).closest(".hero-content").find("video").attr("id");

    if ( $(this).data('type') == "video" && $(this).hasClass("pause") ){
      $(this).removeClass("pause").addClass("play");
      pauseVideo(activeVideo);
    }
    else if ( $(this).data('type') == "video" && $(this).hasClass("play") ){
      $(this).removeClass("play").addClass("pause");
      playVideo(activeVideo);
    }

    if ( $(this).data('type') == "audio" && $(this).hasClass("mute") ){
      $(this).removeClass("mute").addClass("unmute");
      muteVideo(activeVideo);
    }
    else if ( $(this).data('type') == "audio" && $(this).hasClass("unmute") ){
      $(this).removeClass("unmute").addClass("mute");
      unmuteVideo(activeVideo);
    }

    return false;
  });

  // Events box - play/pause video on hover in/out

  $(".events-wrapper > div .bg-video-teaser").hover(
    function() {
    activeVideo = $(this).parent().find("video").attr("id");
    if ( widthWithScrollBars >= 992 ){
      $(this).addClass( "cover-hide" );
      playVideo(activeVideo);
    }
  }, function() {
    activeVideo = $(this).parent().find("video").attr("id");
    if ( widthWithScrollBars >= 992 ){
      $(this).removeClass( "cover-hide" );
      pauseVideo(activeVideo);
    }
  });

  // Form select - Select 2

  if ($('.customSelect').length){
    $('.customSelect').select2();
  }

  // Discover slider
  function discoverSliderFn(){
    var discoverSlider = new Swiper('.discover-slider .swiper', {
      speed: 1000,
      lazy: false,
      slidesPerView: 'auto',
      loop: true,
      // Navigation arrows
      navigation: {
        nextEl: '.slider-section[data-name="discover-the-region"] .swiper-button-next',
        prevEl: '.slider-section[data-name="discover-the-region"] .swiper-button-prev',
      }
    });
  }

  if ($('.discover-slider').length){
    discoverSliderFn();
  }

  // Gallery slider
  function gallerySliderFn(){
    var gallerySlider = new Swiper('.gallery-slider .swiper', {
      speed: 1000,
      lazy: false,
      slidesPerView: 1,
      loop: true,
      // Navigation arrows
      navigation: {
        nextEl: '.gallery-slider .swiper-button-next',
        prevEl: '.gallery-slider .swiper-button-prev',
      }
    });
  }

  if ($('.gallery-slider').length){
    gallerySliderFn();
  }

  // mobile submenu
  if ($('.menu-main').length){
      $(document).on( "click", ".menu-main > ul > li > *:not(ul)", function() {
        if ( widthWithScrollBars < 992 ){
          $(this).closest("li").toggleClass("open");
        }
      });
  }

  // Custom select with filters
  if ( $('a.filters-select').length ){
    $(".events-search-wrapper, .search-wrapper").append("<div class='filter-blocker'></div>");

    $('body').on('click', 'a.filters-select:not(.active)', function(){
      $(this).addClass("active");
      $("body").toggleClass("filter-open");
      $(this).next().toggleClass("active");

      return false;
    });

    $(document).on("click touchend", ".filter-blocker", function(){
      $("body").removeClass("filter-open");
      $(".filter_select").removeClass("active");
    });

    $('body').on('click', '.panel-heading a', function(){
      $(this).parent().find("span").toggleClass("active");
    });

    $(document).mouseup(function(e) {
      var container = $(".filter_select");
      if (!container.is(e.target)  && container.has(e.target).length === 0) {
        $(".filter_select").removeClass("active");
        $(".list-filter").removeClass("active");
        $("body").removeClass("filter-open");
        $("a.filters-select").removeClass("active");
      }
      return false;
    });
  }

  // accordions in select
  if ( $(".filters-accordion").length ){
    $(document).on( 'click', '.filters-accordion a', function() {
      var activeFilter = $(this);
      activeFilter.parent().toggleClass('active');
      return false;
    });
  }

  // Datepicker with range
  // if ( $('input[data-type="daterange"]').length ){
  //   $('input[data-type="daterange"]').daterangepicker({
  //     startDate: "17/08/2022",
  //     endDate: "22/08/2022",
  //     locale: {
  //       separator: ' to ',
  //       format: 'DD.MM.Y',
  //       fromLabel: "From",
  //       toLabel: "to",
  //       customRangeLabel: "Custom",
  //     }
  //   });
  // }

  // mobile move article image-box
  $("aside > *:first-child").addClass("first-element");

  function moveBoxes(){
    var moveElement = '';
    var targetElement = '';
    $(".style-1").each(function(){
      moveElement = $(this).find(".first-element");
      if ( widthWithScrollBars < 992 ){
        targetElement = $(this).find(".article-content-block:first");
        moveElement.insertAfter(targetElement);
      }
      else{
        targetElement = $(this).find("aside");
        moveElement.prependTo(targetElement);
      }
    });
  }
  if ( $(".style-1").length ){
    moveBoxes();
  }

  // mobile move booking btn
  function moveBookingBtn(){
    var moveElement = '';
    var targetElement = '';
    $(".booking-btn").each(function(){
      moveElement = $(this);//.find(".first-element");
      if ( widthWithScrollBars < 768 ){
        targetElement = $(this).closest(".tab-element").find(".image");
        moveElement.prependTo(targetElement);
      }
      else{
        targetElement = $(this).closest(".tab-element").find(".content");
        moveElement.appendTo(targetElement);
      }
    });
  }
  if ( $(".booking-btn").length ){
    moveBookingBtn();
  }

  // mobile move aside box for hotels page
  function moveHotelBox(){
    var moveElement = '';
    var targetElement = '';
    $(".hotel-info-wrapper .side-detail").each(function(){
      moveElement = $(this).closest(".hotel-info-wrapper").find("aside");
      if ( widthWithScrollBars < 992 ){
        targetElement = $(this).parent().parent().find(".article-content p:first");
        moveElement.insertAfter(targetElement);
      }
      else{
        targetElement = $(this).closest(".hotel-info-wrapper").find(">div:last-child");
        moveElement.prependTo(targetElement);
      }
    });
  }
  if ( $(".hotel-info-wrapper .side-detail").length ){
    moveHotelBox();
  }

  // hotel detail page - map layer
  if ( $(".openMapBtn").length ){
    $(document).on('click', '.openMapBtn', function(){
      $("body").addClass("map-active");
      return false;
    });
    $(document).on('click', '.closeMapBtn', function(){
      $("body").removeClass("map-active");
      return false;
    });
  }

  // Tab component

  function tabComponent(){
    console.log("TEST");
    var tabActive = '';
    var tabIndex = 0;

    $(document).on('click touchend', '.tab-buttons button:not(.active)', function(){
      tabActive = $(this);
      tabActive.parent().find('button').removeClass('active');
      tabActive.addClass('active');

      tabIndex = tabActive.index();

      tabActive.closest('.tab-wrapper').find('.tab-content > .tab-panel').removeClass('active').removeClass('fade');
      tabActive.closest('.tab-wrapper').find('.tab-content > .tab-panel:eq(' + tabIndex + ')').addClass('active');
      setTimeout(function(){
        tabActive.closest('.tab-wrapper').find('.tab-content > .tab-panel:eq(' + tabIndex + ')').addClass('fade');
      },20);
    });
  }
  if ($('.tab-wrapper').length ){
    tabComponent();
  };


  if ($('.accordion-heading').length ){

    $(document).on( 'click', '.accordion-heading', function() {
      var activePanel = $(this);
      activePanel.parent().toggleClass('active');
      return false;
    });

  };

  // OutdoorActive Tabs
  if ($('.tabs-wrapper').length ){
    var tabActive = '';
    var tabIndex = 0;

    $(document).on('click touchend', '.tabs-heading-wrapper a', function(){
      tabActive = $(this);
      tabActive.siblings().removeClass('active');
      tabActive.addClass('active');

      if ($('.tab-wrapper').length ){
        tabComponent();
      }

      tabIndex = tabActive.index();

      tabActive.closest('.tabs-wrapper').find('.tab-content-wrapper > .tab-content').removeClass('active');
      tabActive.closest('.tabs-wrapper').find('.tab-content-wrapper > .tab-content:eq(' + tabIndex + ')').addClass('active');
      setTimeout(function(){
        tabActive.closest('.tabs-wrapper').find('.tab-content-wrapper > .tab-content:eq(' + tabIndex + ')');
      },20);
      return false;
    });
  }

  // Modal pop up
  if ($('.modal.auto').length) {
    new Modal({el: document.querySelector('.modal.auto')}).show();
  }

  // Resize
  function doneResizing(){
    widthWithScrollBars = $(window).width();
    if ( $(".style-1").length ){
      moveBoxes();
    }
    if ( $(".booking-btn").length ){
      moveBookingBtn();
    }
    if ( $(".hotel-info-wrapper").length ){
      moveHotelBox();
    }
  }
  var resizeId;
  $(window).resize(function() {
    clearTimeout(resizeId);
    resizeId = setTimeout(doneResizing, 200);
  });
});
